import React from 'react'
import Svg from "../Svg";
import { SvgProps } from "../types";

const AppleStore: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="154" height="38" viewBox="0 0 154 38" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props}>
            <path d="M22.196 17.7942C22.2121 16.5423 22.5447 15.3147 23.1627 14.2258C23.7807 13.1368 24.6642 12.2219 25.7308 11.5662C25.0532 10.5984 24.1593 9.802 23.12 9.24017C22.0808 8.67835 20.9249 8.36658 19.7441 8.32963C17.2252 8.06524 14.7833 9.83688 13.4997 9.83688C12.1913 9.83688 10.2151 8.35588 8.08697 8.39967C6.71046 8.44414 5.36894 8.84442 4.19312 9.56151C3.0173 10.2786 2.04729 11.288 1.37759 12.4915C-1.52341 17.5141 0.640473 24.8957 3.41939 28.9556C4.80974 30.9436 6.43466 33.1643 8.56083 33.0855C10.6414 32.9992 11.4185 31.7588 13.9299 31.7588C16.4181 31.7588 17.1471 33.0855 19.3164 33.0355C21.549 32.9992 22.9556 31.0386 24.2972 29.0318C25.2962 27.6152 26.0649 26.0496 26.5748 24.393C25.2778 23.8444 24.1709 22.9261 23.3922 21.7527C22.6135 20.5792 22.1975 19.2025 22.196 17.7942Z" fill="white" />
            <path d="M18.0984 5.65976C19.3156 4.19845 19.9154 2.32018 19.7701 0.423828C17.9104 0.619159 16.1925 1.508 14.9588 2.91324C14.3555 3.59974 13.8936 4.3984 13.5992 5.26355C13.3048 6.1287 13.1838 7.04339 13.2432 7.95533C14.1734 7.96491 15.0937 7.76329 15.9347 7.36566C16.7756 6.96802 17.5155 6.38475 18.0984 5.65976Z" fill="white" />
            <path d="M52.4961 28.0533H45.396L43.6909 33.0879H40.6836L47.4087 14.4609H50.5332L57.2583 33.0879H54.1997L52.4961 28.0533ZM46.1313 25.73H51.7593L48.9849 17.5591H48.9073L46.1313 25.73Z" fill="white" />
            <path d="M71.7823 26.2989C71.7823 30.5191 69.5235 33.2305 66.1148 33.2305C65.2513 33.2757 64.3926 33.0768 63.6369 32.6566C62.8812 32.2364 62.2591 31.6119 61.8418 30.8545H61.7773V37.5811H58.9897V19.5079H61.688V21.7667H61.7393C62.1757 21.013 62.8083 20.3917 63.5698 19.9689C64.3312 19.5461 65.193 19.3377 66.0635 19.3658C69.5102 19.3658 71.7823 22.0904 71.7823 26.2989ZM68.917 26.2989C68.917 23.5494 67.4961 21.7418 65.3282 21.7418C63.1983 21.7418 61.7657 23.5875 61.7657 26.2989C61.7657 29.0352 63.1983 30.8678 65.3282 30.8678C67.4961 30.8678 68.917 29.0733 68.917 26.2989Z" fill="white" />
            <path d="M86.7295 26.2989C86.7295 30.5191 84.4707 33.2305 81.0621 33.2305C80.1986 33.2757 79.3398 33.0768 78.5841 32.6566C77.8284 32.2364 77.2064 31.6119 76.7891 30.8545H76.7246V37.5811H73.937V19.5079H76.6352V21.7667H76.6865C77.1229 21.013 77.7555 20.3917 78.517 19.9689C79.2784 19.5461 80.1403 19.3377 81.0107 19.3658C84.4576 19.3658 86.7295 22.0904 86.7295 26.2989ZM83.8643 26.2989C83.8643 23.5494 82.4434 21.7418 80.2754 21.7418C78.1455 21.7418 76.7129 23.5875 76.7129 26.2989C76.7129 29.0352 78.1455 30.8677 80.2754 30.8677C82.4434 30.8677 83.8643 29.0733 83.8643 26.2989H83.8643Z" fill="white" />
            <path d="M96.6084 27.898C96.815 29.7452 98.6094 30.958 101.062 30.958C103.411 30.958 105.102 29.7451 105.102 28.0796C105.102 26.6338 104.082 25.7681 101.668 25.1748L99.254 24.5933C95.8336 23.7671 94.2457 22.1675 94.2457 19.5718C94.2457 16.3579 97.0464 14.1504 101.023 14.1504C104.959 14.1504 107.658 16.3579 107.749 19.5718H104.935C104.766 17.7129 103.23 16.5908 100.984 16.5908C98.7383 16.5908 97.2017 17.7261 97.2017 19.3784C97.2017 20.6953 98.1831 21.4702 100.584 22.0634L102.636 22.5673C106.458 23.4711 108.046 25.0063 108.046 27.7309C108.046 31.2158 105.27 33.3984 100.855 33.3984C96.7241 33.3984 93.9351 31.267 93.7549 27.8979L96.6084 27.898Z" fill="white" />
            <path d="M114.062 16.293V19.5068H116.645V21.7144H114.062V29.2012C114.062 30.3643 114.579 30.9063 115.714 30.9063C116.021 30.9009 116.327 30.8794 116.631 30.8418V33.0361C116.121 33.1315 115.602 33.1746 115.083 33.165C112.333 33.165 111.261 32.1323 111.261 29.4985V21.7144H109.287V19.5068H111.261V16.293H114.062Z" fill="white" />
            <path d="M118.14 26.2978C118.14 22.0249 120.657 19.3398 124.581 19.3398C128.519 19.3398 131.023 22.0248 131.023 26.2978C131.023 30.5825 128.532 33.2559 124.581 33.2559C120.632 33.2559 118.14 30.5825 118.14 26.2978ZM128.183 26.2978C128.183 23.3667 126.84 21.6367 124.581 21.6367C122.322 21.6367 120.98 23.3799 120.98 26.2978C120.98 29.2407 122.322 30.9575 124.581 30.9575C126.84 30.9575 128.183 29.2407 128.183 26.2978H128.183Z" fill="white" />
            <path d="M133.322 19.5078H135.98V21.8193H136.045C136.225 21.0973 136.648 20.4593 137.243 20.0125C137.838 19.5657 138.568 19.3373 139.312 19.3656C139.633 19.3645 139.953 19.3994 140.267 19.4697V22.0771C139.861 21.9531 139.438 21.8962 139.014 21.9086C138.609 21.8922 138.205 21.9636 137.831 22.1179C137.456 22.2723 137.119 22.5059 136.843 22.8027C136.567 23.0995 136.359 23.4526 136.232 23.8376C136.105 24.2226 136.064 24.6304 136.109 25.0331V33.0883H133.322L133.322 19.5078Z" fill="white" />
            <path d="M153.119 29.0986C152.744 31.564 150.343 33.2559 147.271 33.2559C143.321 33.2559 140.869 30.6089 140.869 26.3623C140.869 22.1025 143.334 19.3398 147.154 19.3398C150.912 19.3398 153.274 21.9209 153.274 26.0386V26.9937H143.683V27.1621C143.638 27.6619 143.701 28.1654 143.866 28.6392C144.031 29.1129 144.296 29.5461 144.641 29.9099C144.987 30.2737 145.406 30.5599 145.87 30.7493C146.335 30.9388 146.835 31.0272 147.336 31.0088C147.995 31.0705 148.656 30.9179 149.221 30.5738C149.786 30.2296 150.225 29.7122 150.472 29.0986L153.119 29.0986ZM143.696 25.0454H150.485C150.51 24.596 150.442 24.1463 150.285 23.7245C150.128 23.3027 149.886 22.9179 149.573 22.5942C149.26 22.2706 148.884 22.0151 148.468 21.8437C148.051 21.6724 147.604 21.589 147.154 21.5986C146.7 21.5959 146.25 21.6831 145.83 21.8553C145.41 22.0274 145.028 22.281 144.707 22.6014C144.385 22.9219 144.13 23.3028 143.957 23.7223C143.783 24.1418 143.695 24.5915 143.696 25.0454V25.0454Z" fill="white" />
            <path d="M44.2329 0.439844C44.8173 0.397905 45.4038 0.486195 45.9499 0.698344C46.4961 0.910494 46.9884 1.24121 47.3913 1.66665C47.7941 2.09209 48.0976 2.60164 48.2797 3.15854C48.4618 3.71545 48.518 4.30583 48.4443 4.8871C48.4443 7.74648 46.8989 9.3901 44.2329 9.3901H41V0.439844H44.2329ZM42.3901 8.12434H44.0776C44.4952 8.14931 44.9132 8.08083 45.301 7.92391C45.6888 7.76699 46.0367 7.52557 46.3194 7.21722C46.6022 6.90887 46.8126 6.54136 46.9354 6.14143C47.0582 5.7415 47.0903 5.31923 47.0293 4.90534C47.0858 4.49309 47.0505 4.07344 46.9258 3.67644C46.8012 3.27944 46.5902 2.91494 46.3081 2.60904C46.026 2.30315 45.6798 2.06344 45.2941 1.90708C44.9085 1.75073 44.4931 1.6816 44.0776 1.70466H42.3901V8.12434Z" fill="white" />
            <path d="M50.0146 6.01238C49.9721 5.5685 50.0229 5.12065 50.1637 4.69755C50.3044 4.27444 50.532 3.88543 50.832 3.55546C51.1319 3.22548 51.4974 2.96183 51.9052 2.78142C52.313 2.60101 52.754 2.50781 53.1999 2.50781C53.6458 2.50781 54.0868 2.60101 54.4945 2.78142C54.9023 2.96183 55.2679 3.22548 55.5678 3.55546C55.8677 3.88543 56.0954 4.27444 56.2361 4.69755C56.3769 5.12065 56.4276 5.5685 56.3852 6.01238C56.4285 6.45671 56.3783 6.90518 56.2379 7.32897C56.0975 7.75276 55.87 8.14249 55.57 8.47311C55.27 8.80373 54.9042 9.06793 54.496 9.24873C54.0878 9.42953 53.6463 9.52294 53.1999 9.52294C52.7534 9.52294 52.3119 9.42953 51.9038 9.24873C51.4956 9.06793 51.1297 8.80373 50.8297 8.47311C50.5298 8.14249 50.3023 7.75276 50.1619 7.32897C50.0215 6.90518 49.9713 6.45671 50.0146 6.01238ZM55.0141 6.01238C55.0141 4.54827 54.3564 3.69208 53.2021 3.69208C52.0434 3.69208 51.3916 4.54827 51.3916 6.01239C51.3916 7.48823 52.0434 8.33783 53.2021 8.33783C54.3564 8.33781 55.0141 7.48235 55.0141 6.01238H55.0141Z" fill="white" />
            <path d="M64.8535 9.39111H63.4707L62.0747 4.4165H61.9692L60.5791 9.39111H59.2095L57.3477 2.63672H58.6997L59.9097 7.79072H60.0093L61.398 2.63672H62.6768L64.0654 7.79072H64.1709L65.375 2.63672H66.708L64.8535 9.39111Z" fill="white" />
            <path d="M68.2739 2.63703H69.5571V3.71002H69.6567C69.8257 3.32464 70.1107 3.00158 70.472 2.78588C70.8333 2.57017 71.253 2.47256 71.6724 2.50665C72.001 2.48194 72.331 2.53149 72.6379 2.65164C72.9448 2.77178 73.2207 2.95944 73.4453 3.2007C73.6698 3.44197 73.8372 3.73066 73.935 4.04539C74.0328 4.36012 74.0585 4.69282 74.0103 5.01885V9.39135H72.6773V5.35359C72.6773 4.26814 72.2056 3.72834 71.2197 3.72834C70.9966 3.71795 70.7738 3.75592 70.5668 3.83967C70.3597 3.92341 70.1731 4.05095 70.02 4.21352C69.8668 4.3761 69.7506 4.56988 69.6793 4.78158C69.608 4.99327 69.5833 5.21788 69.6069 5.44V9.39142H68.2739L68.2739 2.63703Z" fill="white" />
            <path d="M76.1343 0H77.4673V9.39111H76.1343V0Z" fill="white" />
            <path d="M79.3202 6.01055C79.2778 5.56665 79.3286 5.11877 79.4694 4.69565C79.6102 4.27253 79.8378 3.8835 80.1378 3.55352C80.4377 3.22354 80.8033 2.95989 81.2111 2.77947C81.6189 2.59906 82.0599 2.50586 82.5059 2.50586C82.9518 2.50586 83.3928 2.59906 83.8006 2.77947C84.2084 2.95989 84.574 3.22354 84.874 3.55352C85.1739 3.8835 85.4016 4.27253 85.5424 4.69565C85.6832 5.11877 85.734 5.56665 85.6915 6.01055C85.7347 6.4549 85.6845 6.90338 85.5441 7.32717C85.4037 7.75095 85.1761 8.14067 84.8761 8.47128C84.5761 8.8019 84.2102 9.06609 83.802 9.24688C83.3938 9.42767 82.9523 9.52107 82.5059 9.52107C82.0594 9.52107 81.6179 9.42767 81.2097 9.24688C80.8015 9.06609 80.4356 8.8019 80.1356 8.47128C79.8356 8.14067 79.6081 7.75095 79.4676 7.32717C79.3272 6.90338 79.277 6.4549 79.3202 6.01055ZM84.3197 6.01055C84.3197 4.54645 83.662 3.69025 82.5077 3.69025C81.349 3.69025 80.6972 4.54645 80.6972 6.01057C80.6972 7.4864 81.3491 8.336 82.5077 8.336C83.662 8.33599 84.3197 7.48052 84.3197 6.01055H84.3197Z" fill="white" />
            <path d="M87.0947 7.48241C87.0947 6.26659 88 5.56567 89.6069 5.46605L91.4365 5.36059V4.77758C91.4365 4.0642 90.9648 3.66137 90.0537 3.66137C89.3096 3.66137 88.794 3.93457 88.646 4.41211H87.3555C87.4917 3.25195 88.583 2.50781 90.1152 2.50781C91.8086 2.50781 92.7637 3.35081 92.7637 4.77758V9.39257H91.4805V8.44336H91.375C91.1609 8.78385 90.8603 9.06142 90.5039 9.24774C90.1475 9.43405 89.748 9.52243 89.3462 9.50386C89.0626 9.53335 88.7761 9.5031 88.5049 9.41505C88.2338 9.32701 87.9841 9.18312 87.772 8.99266C87.5598 8.80221 87.39 8.56942 87.2733 8.30929C87.1567 8.04917 87.0958 7.76749 87.0947 7.48241ZM91.4365 6.90526V6.34057L89.7871 6.44603C88.8569 6.50828 88.435 6.82469 88.435 7.42015C88.435 8.02805 88.9624 8.38181 89.6875 8.38181C89.9 8.40332 90.1146 8.38187 90.3186 8.31873C90.5227 8.2556 90.7119 8.15207 90.8751 8.01431C91.0382 7.87654 91.172 7.70737 91.2685 7.51684C91.365 7.32631 91.4221 7.11832 91.4365 6.90526Z" fill="white" />
            <path d="M94.5156 6.01101C94.5156 3.87673 95.6128 2.52468 97.3193 2.52468C97.7415 2.50523 98.1604 2.60633 98.5272 2.81618C98.894 3.02602 99.1935 3.33593 99.3906 3.70968H99.4902V0H100.823V9.39111H99.5459V8.32397H99.4404C99.228 8.6952 98.9181 9.00125 98.5442 9.20898C98.1704 9.4167 97.7468 9.51818 97.3193 9.50242C95.6011 9.5025 94.5156 8.15041 94.5156 6.01101ZM95.8926 6.01101C95.8926 7.44363 96.5679 8.30569 97.6973 8.30569C98.8208 8.30569 99.5152 7.43119 99.5152 6.01687C99.5152 4.60915 98.8135 3.72219 97.6973 3.72219C96.5752 3.72219 95.8926 4.5901 95.8926 6.01101H95.8926Z" fill="white" />
            <path d="M106.338 6.01238C106.296 5.5685 106.347 5.12065 106.487 4.69755C106.628 4.27444 106.856 3.88543 107.156 3.55546C107.456 3.22548 107.821 2.96183 108.229 2.78142C108.637 2.60101 109.078 2.50781 109.524 2.50781C109.97 2.50781 110.41 2.60101 110.818 2.78142C111.226 2.96183 111.592 3.22548 111.892 3.55546C112.191 3.88543 112.419 4.27444 112.56 4.69755C112.701 5.12065 112.751 5.5685 112.709 6.01238C112.752 6.45671 112.702 6.90518 112.562 7.32897C112.421 7.75276 112.194 8.14249 111.894 8.47311C111.594 8.80373 111.228 9.06793 110.82 9.24873C110.412 9.42953 109.97 9.52294 109.524 9.52294C109.077 9.52294 108.636 9.42953 108.227 9.24873C107.819 9.06793 107.453 8.80373 107.153 8.47311C106.853 8.14249 106.626 7.75276 106.486 7.32897C106.345 6.90518 106.295 6.45671 106.338 6.01238ZM111.338 6.01238C111.338 4.54827 110.68 3.69208 109.526 3.69208C108.367 3.69208 107.715 4.54827 107.715 6.01239C107.715 7.48823 108.367 8.33783 109.526 8.33783C110.68 8.33781 111.338 7.48235 111.338 6.01238Z" fill="white" />
            <path d="M114.498 2.63703H115.781V3.71002H115.881C116.05 3.32464 116.335 3.00158 116.696 2.78588C117.057 2.57017 117.477 2.47256 117.896 2.50665C118.225 2.48194 118.555 2.53149 118.862 2.65164C119.169 2.77178 119.445 2.95944 119.669 3.2007C119.894 3.44197 120.061 3.73066 120.159 4.04539C120.257 4.36012 120.283 4.69282 120.234 5.01885V9.39135H118.901V5.35359C118.901 4.26814 118.43 3.72834 117.444 3.72834C117.221 3.71795 116.998 3.75592 116.791 3.83967C116.584 3.92341 116.397 4.05095 116.244 4.21352C116.091 4.3761 115.975 4.56988 115.903 4.78158C115.832 4.99327 115.807 5.21788 115.831 5.44V9.39142H114.498V2.63703Z" fill="white" />
            <path d="M127.766 0.955078V2.66748H129.23V3.79029H127.766V7.26343C127.766 7.97095 128.058 8.28076 128.721 8.28076C128.891 8.28022 129.061 8.26995 129.23 8.25V9.36034C128.99 9.40317 128.748 9.42595 128.504 9.42846C127.022 9.42846 126.432 8.90697 126.432 7.60473V3.79023H125.359V2.66742H126.432V0.955078H127.766Z" fill="white" />
            <path d="M131.051 0H132.372V3.72218H132.478C132.655 3.33319 132.948 3.00831 133.316 2.79179C133.685 2.57527 134.111 2.47764 134.537 2.51221C134.864 2.49442 135.191 2.54901 135.494 2.67207C135.798 2.79513 136.07 2.98364 136.292 3.22415C136.515 3.46466 136.681 3.75124 136.78 4.06344C136.878 4.37564 136.907 4.70577 136.863 5.03028V9.39113H135.529V5.35913C135.529 4.28027 135.026 3.73388 134.085 3.73388C133.855 3.71509 133.625 3.74658 133.409 3.82616C133.194 3.90574 132.998 4.03148 132.836 4.19459C132.674 4.3577 132.55 4.55423 132.471 4.77043C132.393 4.98664 132.363 5.2173 132.384 5.44628V9.3911H131.051L131.051 0Z" fill="white" />
            <path d="M144.635 7.567C144.454 8.18431 144.062 8.71829 143.527 9.07551C142.992 9.43274 142.348 9.59046 141.708 9.5211C141.263 9.53284 140.821 9.44761 140.413 9.27132C140.004 9.09503 139.638 8.8319 139.341 8.50019C139.045 8.16848 138.823 7.77615 138.693 7.35042C138.563 6.92468 138.528 6.47575 138.588 6.03478C138.529 5.59245 138.566 5.14256 138.695 4.71555C138.825 4.28854 139.045 3.89439 139.34 3.55978C139.636 3.22517 139.999 2.95791 140.407 2.7761C140.814 2.59429 141.256 2.50217 141.703 2.50597C143.582 2.50597 144.716 3.78997 144.716 5.91097V6.37611H139.946V6.45081C139.925 6.69868 139.957 6.94817 140.038 7.1833C140.119 7.41842 140.248 7.63399 140.418 7.81617C140.587 7.99835 140.793 8.14313 141.021 8.24123C141.25 8.33932 141.496 8.38857 141.745 8.38581C142.064 8.42409 142.387 8.36667 142.673 8.22086C142.959 8.07505 143.196 7.84743 143.352 7.56696L144.635 7.567ZM139.946 5.39025H143.358C143.375 5.16356 143.344 4.93586 143.267 4.72178C143.191 4.5077 143.071 4.31198 142.914 4.1472C142.757 3.98241 142.568 3.8522 142.358 3.76494C142.148 3.67768 141.923 3.63529 141.695 3.6405C141.465 3.6376 141.236 3.68089 141.022 3.7678C140.809 3.85472 140.615 3.98351 140.452 4.14658C140.289 4.30965 140.16 4.5037 140.073 4.71729C139.986 4.93089 139.943 5.1597 139.946 5.39025H139.946Z" fill="white" />
        </Svg>
    )
}
export default AppleStore
