import React, { useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {
    HeaderNavBar,
    // NavBarContainer,
    RouterNavLink,
    OffCanvasWrapper,
    OffcanvasCloseIcon,
    // RouterATag
} from "./styles";
import { Flex } from "components/Box";
import useViewport from "hooks/useViewport";
import { useUserManager } from "state/user/hooks";
import { LogoIcon, GamesFiIcon } from "components/Svg";
import { ContainerRow } from "components/ContainerCustom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Text } from "components/Text";
import userImage from 'assets/images/user.svg'
import NavbarSettings from "./NavbarSettings";

const Header: React.FC = () => {
    const { width } = useViewport();
    const isMobile = width < 992
    const isWeb = width >= 992
    const [user] = useUserManager();
    

    //For Navigation of ID's in Menus
    const { pathname, key, hash } = useLocation();
    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === "") {
            // window.onload = function () {
            window.scrollTo(0, 0);
            // }
        }
        // else scroll to id
        else {
            const id = hash.replace("#", "");
            let element = document.getElementById(id);
            setTimeout(async () => {
                while (!element) {
                    await new Promise((r) => setTimeout(() => r(true), 10));
                    element = document.getElementById(id);
                }
                element.scrollIntoView(true);
                const topOfElement =
                    window.pageYOffset + element.getBoundingClientRect().top - 80;
                window.scroll({ top: topOfElement, behavior: "smooth" });
            }, 1000);
        };

    }, [pathname, hash, key]); // do this on route change

    //For Toggle, Remove the Tabs.
    const [expanded, setExpanded] = useState(false);
    const toggleMenu = () => {
        isMobile && (expanded ? setExpanded(false) : setExpanded(true));
    };

    const canvasBgColor = "radial-gradient(74.5% 111.75% at 49.73% 106.8%,#3B0864 0%,#200942 39%,#0B0B28 77%,#030B1E 100%)";
    const canvasWidth = "80%";

    return (
        <HeaderNavBar bg="light" expand="lg" fixed="top" expanded={expanded}>
            <ContainerRow>
                {isMobile && (
                    <>
                        <Navbar.Toggle className="nav-body-toggle" aria-controls={`offcanvasNavbar-expand-${expanded}`} onClick={toggleMenu} />
                        <Navbar.Toggle onClick={toggleMenu} aria-controls={`offcanvasNavbar-expand-${expanded}`} />
                        <Navbar.Brand className="NavlogoWidth">
                            <Link to="/"
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: "smooth"
                                    });
                                }}
                            >
                                <GamesFiIcon />
                            </Link>
                        </Navbar.Brand>
                        {user && <NavbarSettings toggleMenu={toggleMenu} isWeb={isWeb} />}
                    </>
                )
                }
                <Navbar.Offcanvas
                    show={expanded ? expanded : undefined} onHide={() => setExpanded(false)}
                    placement="start"
                    backdrop={false}
                    scroll={false}
                    id={`offcanvasNavbar-expand-${expanded}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expanded}`}
                    style={{ background: isMobile && canvasBgColor, maxWidth: isMobile && canvasWidth, border: 'none' }}
                >
                    <OffCanvasWrapper>
                        <OffcanvasCloseIcon closeButton />
                        <Offcanvas.Body>
                            {isMobile && user && <Flex alignItems="center" margin="2rem 0">
                                <img src={userImage} alt="logo" />
                                <Text>{user.email}</Text>
                            </Flex>}
                            <Flex flex={2}>
                                {!isMobile &&
                                    <Navbar.Brand>
                                        <Link to="/"
                                            onClick={() => {
                                                window.scrollTo({
                                                    top: 0,
                                                    behavior: "smooth"
                                                });
                                            }}
                                        >
                                            <LogoIcon />
                                        </Link>
                                    </Navbar.Brand>}
                            </Flex>
                            {isMobile && user && <hr />}
                            <Flex flex={5} justifyContent={isMobile ? "start" : "center"} marginTop={isMobile ? '3rem' : ''}>
                                <Flex
                                    flexDirection={isMobile ? "column" : "row"}
                                    alignItems={isMobile ? "start" : "center"}
                                >
                                    <RouterNavLink to="/"
                                        onClick={() => {
                                            toggleMenu();
                                            window.scrollTo({
                                                top: 0,
                                                behavior: "smooth"
                                            });
                                        }}
                                    >Home</RouterNavLink>
                                  
                                    <RouterNavLink to={"#contactus"} onClick={toggleMenu}>Contact us</RouterNavLink>
                                </Flex>
                            </Flex>
                            {isMobile && <hr />}
                        </Offcanvas.Body>
                    </OffCanvasWrapper>
                </Navbar.Offcanvas>
            </ContainerRow >
        </HeaderNavBar >
    );
};

export default Header;
