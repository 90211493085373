import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Flex } from "components/Box";
import { Text } from "components/Text";
import {
    ColWrapper10,
    ColWrapper90,
    ContactWrapper,
    FooterHeading,
    FormErrorMessage,
    RealTimeMessage,
    TextArea,
} from "../styles";
import { useForm, SubmitHandler } from "react-hook-form";
import useTheme from "hooks/useTheme";
import useViewport from "hooks/useViewport";
import {  Sms } from "components/Svg";
import { FormButton, Input } from "views/Home/components/forms/styles";
import { contactUs } from "service/apiRequest.service.request";
import Spinner from "components/Spinner";
import { IContactUs } from "utils/commonTypes";
import { ContactModal } from "./ContactModal";
import { useModal } from "widgets/Modal";
import { EMAIL_VALIDATION } from "config/constants/endpoints";
import { AxiosError } from "axios";
import { ErrorData } from "state/user/types";

const ContactUs: React.FC = () => {
    const emailValidation = EMAIL_VALIDATION;

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors }
    } = useForm<IContactUs>({
        mode: "onChange" || "onSubmit"
    });

    const [nameLength, setNameLength] = useState(0);
    const nameLimit = 30;
    const nameCountHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNameLength(event.target.value.length);
        setNameLength(event.target.value.replace(/^\s+/g, "").length);
    }

    const [messageLength, setMessageLength] = useState(0);
    const messageLimit = 500;
    const messageCountHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessageLength(event.target.value.length);
    }

    const counterReset = () => {
        reset();
        setNameLength(0);
        setMessageLength(0);
    }
    const [errormessage, setErrorMessage] = useState("");

    const onSubmit: SubmitHandler<IContactUs> = async (data) => {
        setLoader(true)
        try {
            const response = await contactUs(data?.name, data?.email, data?.message);
            if (response.code === 200) {
                counterReset();
                showContactModal();
                setLoader(false);
            }
        } catch (error) {
            const errormessage = (error as AxiosError)?.response?.data;
            setErrorMessage((errormessage as ErrorData)?.message);
            setLoader(false);
        }
    }

    const [showContactModal, onContactDismiss] = useModal(
        <ContactModal handleClose={() => onContactDismiss()} />,
        true
    );

    const [loader, setLoader] = useState(false);
    const { theme } = useTheme();
    const { width } = useViewport();

    return (
        <>
            <Col lg={4}>
                <Flex
                    flexDirection={width < 576 ? "column" : "row"}
                    justifyContent="space-between"
                    margin={width < 992 ? "2rem 0rem " : "0rem"}
                >
                    <Flex
                        flexDirection="column"
                        margin={width < 576 ? "1rem 0rem " : "0rem"}
                    >
                        <FooterHeading textAlign='left'>
                            Contact us
                        </FooterHeading>

                        <Text
                            fontFamily="Avenir Roman"
                            fontWeight="400"
                            margin="1rem 0rem"
                            color={theme.colors.textPrimary}
                        >
                            Fill up the form and our team will get back
                            <br />
                            to you within 24 hours.
                        </Text>

                        {/*<ContactWrapper>*/}
                        {/*    <ColWrapper10>*/}
                        {/*        <Location />*/}
                        {/*    </ColWrapper10>*/}
                        
                        {/*    <ColWrapper90>*/}
                        {/*        <Text*/}
                        {/*            fontFamily="Avenir Roman"*/}
                        {/*            fontSize="1.6rem"*/}
                        {/*            fontWeight="400"*/}
                        {/*            color={theme.colors.textPrimary}*/}
                        {/*        >*/}
                        {/*            30 Aulike St. suite 105 Kailua, <br />*/}
                        {/*            Hawaii 96734*/}
                        {/*        </Text>*/}
                        {/*    </ColWrapper90>*/}
                        {/*</ContactWrapper>*/}

                        <ContactWrapper>
                            <ColWrapper10>
                                <Sms />
                            </ColWrapper10>

                            <ColWrapper90>
                                <a href="mailto:gamesfi.org111@gmail.com">gamesfi.org111@gmail.com</a>
                            </ColWrapper90>
                        </ContactWrapper>
                    </Flex>
                </Flex>
            </Col>

            <Col lg={4}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <RealTimeMessage>
                        <Input
                            type="text"
                            name="name"
                            placeholder="Enter Full Name"
                            {...register("name", {
                                required: "*Name is Required.",
                                onChange: nameCountHandler,
                                maxLength: {
                                    value: 30,
                                    message: "*Name should be less than 30 characters."
                                },
                                validate: {
                                    whiteSpace: (value) => value.trim().length !== 0 || "*Name should not contain white spaces.",
                                    pattern:
                                        value => /^[a-zA-Z ]*$/.test(value) || "*Only Alphabets are Allowed.",
                                }
                            })}
                            className={errors.name ? "invalid" : ""}
                            style={{ marginBottom: "0rem" }}
                        />
                        <Flex justifyContent='space-between'>
                            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                            <RealTimeMessage>{nameLength} / {nameLimit} </RealTimeMessage>
                        </Flex>
                    </RealTimeMessage>

                    <RealTimeMessage>
                        <Input
                            type="text"
                            name="email"
                            placeholder="Enter Email Address"
                            {...register("email", {
                                required: '*Email is Required.',
                                pattern: {
                                    value: emailValidation,
                                    message: "Invalid Email Address"
                                },
                            })}
                            className={errors.email ? "invalid" : ""}
                            style={{ marginBottom: "0rem" }}
                        />
                        <Flex justifyContent='space-between'>
                            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                            <RealTimeMessage />
                        </Flex>
                    </RealTimeMessage>

                    <RealTimeMessage className="Scroller">
                        <TextArea
                            cols={20}
                            rows={7}
                            name="message"
                            placeholder="Enter Message"
                            {...register("message", {
                                required: '*Message is Required.',
                                onChange: messageCountHandler,
                                maxLength: {
                                    value: 500,
                                    message: "*Message should be less than 500 characters."
                                },
                                minLength: {
                                    value: 1,
                                    message: "*Message should be more than 1 character."
                                },
                                validate: {
                                    whiteSpace: (value) => value.trim().length !== 0 || "*Name should not contain white spaces.",
                                },
                            })}
                            className={errors.message ? "invalid" : ""}
                            style={{ marginBottom: "0rem" }}
                        />
                        <Flex justifyContent='space-between'>
                            <FormErrorMessage>{errors.message?.message}</FormErrorMessage>
                            <RealTimeMessage>{messageLength} / {messageLimit} </RealTimeMessage>
                        </Flex>
                    </RealTimeMessage>
                    <FormErrorMessage>{errormessage}</FormErrorMessage>

                    <FormButton type="submit">
                        {loader ? <Spinner classname="m-0" /> : "Submit"}
                    </FormButton>
                </form>
            </Col>
        </>
    );
};
export default ContactUs;
