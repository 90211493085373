import { ApplicationRoutes } from "./types";
import { lazy } from "react";
const Home = lazy(() => import("views/Home"));
const ContactUs = lazy(() => import("components/Footer/components/ContactUs"));

export const routes = [
  {
    route: ApplicationRoutes.HOME,
    component: Home,
    exact: true,
    isHeaderShow: true,
    isFooterShow: true,
    isAuth: false,
  },
  {
    route: ApplicationRoutes.CONTACT,
    component: ContactUs,
    exact: true,
    isHeaderShow: true,
    isFooterShow: true,
    isAuth: true,
  },
];
